.loading-box{
  width:100%;
  height:100vh;
  .loading-auto{
    width:300px;
    text-align:center;
    position:absolute;
    top:35%;
    left:50%;
    transform:translateX(-50%);
  }
}